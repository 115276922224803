.menu-list-section ul {
  display: flex;
  gap: 20px; // Adjust spacing between links
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-list-section ul li {
  margin: 0;
  border-right: 1px solid #705e5e;
  padding-right: 10px;
}

.menu-list-section ul li:nth-last-child(1) {
  border-right: 0px;
}

.menu-list-section ul li a {
  font-weight: 600;
  color: #0e1b1d;
  text-decoration: none;
  font-size: 14px;
}

.copyright-section {
  text-align: center;
  color: #0e1b1d;
  font-weight: 600;
  margin-left: -80px !important;
}

.social-section ul {
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.social-section ul li {
  margin: 0;
}

@media (max-width: 900px) {
  .copyright-section {
    margin-left: 0px !important;
  }
}
