.plans {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;

  .plan-block {
    border: 2px solid #283436;
    border-radius: 15px;
    background: url(../../assets/images/result-chart-background.png) no-repeat
      white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    position: relative;

    .plan-title {
      text-align: center;
      text-transform: uppercase;
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      justify-content: center;
    }

    .plan-detail {
      display: flex;
      justify-content: center;
      display: block;
      text-align: center;
      color: white;
      padding-bottom: 70px;
      .plan-head {
        font-weight: 600;
        margin-bottom: 12px;
        min-height: 35px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 0.8rem !important;
        justify-content: center;
      }
      .ImgWrap {
        display: flex;
        margin: auto;
        width: 80%;
        height: 150px;
        margin-bottom: 20px;
        max-width: 100%;
        @media screen and (max-width: 598px) {
          width: unset;
          max-width: 50%;
        }
        @media screen and (max-width: 440px) {
          max-width: 80%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .plan-desc {
        margin-bottom: 15px;
        font-size: 0.8rem !important;
        justify-content: center;
      }
    }
    .BuyNowOption {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0px;
      width: 100%;
      margin-bottom: 20px;

      .TextWrap {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        width: 100%;
        p {
          color: #fff;
          font-size: 0.8rem !important;
        }
      }
      .buy-now-button {
        background-color: #e29c2e;
        color: #fff !important;
        padding: 5px 20px;
        font-size: 0.8rem;
      }
    }
  }
}

// Media query to keep 3 cards in a line
// @media (max-width: 1024px) {
// .plan-block {
//   flex: 0 0 31%; // Ensure three cards per line on smaller screens
//   max-width: 31%;
// }
// }

@media (max-width: 768px) {
  .plan-block {
    flex: 0 0 100%; // Stack cards vertically on very small screens
    max-width: 100%;
  }
}

.modal {
  padding: 0px;
  width: 630px;
}

.close-pop-wrap {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.desc-wrap {
  padding: 25px 30px;
  margin-bottom: 25px;
  // height: 200px;
  overflow-y: auto;

  @media screen and (max-width: 540px) {
    height: 380px;
  }

  p {
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: 0.021em;
    color: #364143;
  }
}

.payment-table {
  height: calc(100vh - 140px);
  overflow: auto;

  @media screen and (max-width: 767px) {
    height: unset;
  }
}
