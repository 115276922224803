.header__links {
  a {
    font-weight: 600;
    color: black;
  }
  a:hover,
  .logout:hover {
    color: #ffae00 !important;
    font-weight: 600;
  }

  a.active-link {
    border-bottom: 2px solid #ffae00;
  }

  .logout {
    font-weight: 600;
    padding-right: 0px;
  }
  .header__welcomeuser {
    div {
      font-weight: 600;
      color: black !important;
    }
  }
}

.menuItem__active-link {
  background: #ffae00 !important;
  color: #fff !important;
}
// .header__links a::after {
//   content: "";
//   display: block;
//   width: 0;
//   height: 2px;
//   background: #ffae00;
//   transition: width 0.3s;
// }
// .header__links a:hover::after {
//   width: 100%;
// }

.logo-container {
  height: 50px;
  margin-left: -10px;
}
@media (max-width: 600px) {
  .logo-container {
    height: 35px !important;
    width: 120px;
  }
}
